<template>
  <div class="page">
    <div class="title">标准合同列表</div>

    <el-divider></el-divider>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <!-- input -->
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch">搜索</div>
      </div>
      <div class="filter_btns">
        <div
          class="custom_button plain no_select"
          @click="$router.push({ name: 'ContractTemplate' })"
        >
          模板选择
        </div>
        <div class="custom_button no_select" @click="toBatchSign">
          批量签署
        </div>
      </div>
    </div>

    <!-- table -->
    <el-table
      id="table"
      ref="table"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%;"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        prop="title"
        label="合同名称"
        min-width="150"
      ></el-table-column>
      <el-table-column
        prop="template_id"
        label="合同模板编号"
        min-width="150"
      ></el-table-column>
      <el-table-column
        prop="add_date"
        label="创建时间"
        min-width="150"
      ></el-table-column>
      <el-table-column prop="post" label="适用岗位"></el-table-column>
      <el-table-column prop="base_pay" label="在用人数"></el-table-column>
      <el-table-column
        prop="net_pay"
        label="修改时间"
        min-width="150"
      ></el-table-column>
      <el-table-column prop="bonus" label="签署状态"></el-table-column>
      <el-table-column label="操作" min-width="180" fixed="right">
        <template v-slot:default="scope">
          <el-dropdown size="mini" split-button type="primary">
            操作
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="generateContract(scope.row)"
                >生成合同</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="openWindow(scope.row.contract_url)"
                >打印</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <div class="operations">
                        <div @click="generateContract(scope.row)">生成合同</div>
                        <div @click="openWindow(scope.row.contract_url)">
                            打印
                        </div>
                    </div> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>

    <el-dialog title="生成合同" width="40%" :visible.sync="dialogFormVisible2">
      <el-form :model="gForm" ref="gForm" :rules="rules2">
        <el-form-item label="合同名称" prop="doc_title">
          <el-input v-model="gForm.doc_title"></el-input>
        </el-form-item>

        <el-form-item label="签约对象：" prop="type">
          <el-radio-group v-model="gForm.type">
            <el-radio label="1" :disabled="user.is_store_son != 1"
              >企业</el-radio
            >
            <el-radio label="2">员工</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="选择员工"
          prop="party_b_fdd_userid"
          v-if="gForm.type == 2"
        >
          <el-select
            v-model="gForm.party_b_fdd_userid"
            filterable
            reserve-keyword
            placeholder="请输入员工姓名"
            autocomplete="off"
          >
            <el-option
              v-for="item in employee_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="选择企业"
          prop="party_b_fdd_userid"
          v-if="gForm.type == 1"
        >
          <el-select
            v-model="gForm.party_b_fdd_userid"
            filterable
            reserve-keyword
            placeholder="请输入企业名称"
          >
            <el-option
              v-for="item in enterprise_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="item"
          :prop="item"
          v-for="(item, index) in template_params"
          :key="index"
        >
          <el-input v-model="gForm[template_params[index]]"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="onGenSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="批量签署" width="40%" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="签署员工" prop="party_b_fdd_userids">
          <el-select
            style="witdh: 100%;"
            v-model="form.party_b_fdd_userids"
            filterable
            multiple
            reserve-keyword
            placeholder="请输入员工姓名"
            :remote-method="remoteMethod"
            :loading="employee_loading"
          >
            <el-option
              v-for="item in employee_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="!item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onBatchSignSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      remoteMethod: "",
      search: {
        keyword: "",
      },
      tableData: [],
      // 分页参数
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      dialogFormVisible: false,
      dialogFormVisible2: false,
      employee_loading: false,
      employee_options: [],
      enterprise_options: [],
      template_params: [],
      gForm: {
        template_id: "",
        parameter_map: "",
        doc_title: "",
        party_b_fdd_userid: "",
        type: "2",
      },
      form: {
        template_ids: "",
        party_b_fdd_userids: "",
      },

      rules2: {
        doc_title: [
          {
            required: true,
            message: "请填写合同名称",
            trigger: "blur",
          },
        ],
        party_b_fdd_userid: [
          {
            required: true,
            message: "该员工未注册法大大",
            trigger: "change",
          },
        ],
      },
      rules: {
        party_b_fdd_userids: [
          {
            required: true,
            message: "请至少选择一个员工进行签署",
            trigger: "blur",
          },
        ],
      },

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();

    /**
     * 获取所有员工
     */
    this.getFddEmployeeList({
      page: 1,
      size: 10000,
    })
      .then((res) => {
        console.log(res);
        this.employee_options = res.data.data.list.map((item) => {
          return {
            label: item.real_name,
            value: item.fddUserInfo?.id,
          };
        });
      })
      .catch((e) => {
        console.error(e);
      });

    /**
     * 获取所有企业
     */
    this.getSubsidiaryList({
      page: 1,
      size: 10000,
    })
      .then((res) => {
        console.log(res);
        this.enterprise_options = res.data.data.map((item) => {
          return {
            label: item.name,
            value: item.fddUserInfo?.id,
          };
        });
      })
      .catch((e) => {
        console.error(e);
      });
  },
  computed: {
    ...mapGetters("users", {
      user: "user",
    }),
  },
  methods: {
    ...mapActions("contract", [
      "getStandardContractList",
      "getFddEmployeeList",
      "postGenerateContract",
      "getSubsidiaryList",
      "postBatchSign",
    ]),

    openWindow(url) {
      window.open(url, "_blank");
    },

    /**
     * 批量签署
     */
    toBatchSign() {
      if (this.$refs.table.store.states.selection.length > 0) {
        this.form.party_b_fdd_userids = "";
        this.form.template_ids = this.$refs.table.store.states.selection.map(
          (item) => item.template_id
        );
        this.dialogFormVisible = true;
      } else {
        this.$message.warning("请至少选择一项进行操作");
      }
    },
    onBatchSignSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false;
          let loading = this.$loading();

          this.form.party_b_fdd_userids = this.form.party_b_fdd_userids.join(
            ","
          );

          console.log(this.form);
          this.postBatchSign(this.form)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },

    loadDataFactory(keyword = null) {
      let params = {};
      if (keyword) {
        params.keywords = keyword;
      }
      return () => {
        params.page = this.page.page;
        let loading = this.$loading();
        this.getStandardContractList(params)
          .then((res) => {
            console.log(res);
            this.tableData = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            this.$nextTick(() => {
              loading.close();
            });
          });
      };
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      // 生产带有搜索参数的函数
      this.loadData = this.loadDataFactory(this.search.keyword);
      this.loadData();
    },

    /**
     * 生成合同
     */
    generateContract(data) {
      console.log(data);
      this.gForm.template_id = data.template_id;
      this.gForm.doc_title = data.title;
      this.gForm = { ...this.gForm, ...JSON.parse(data.template_params) };
      this.template_params = Object.keys(JSON.parse(data.template_params));
      this.dialogFormVisible2 = true;
    },

    onGenSubmit() {
      this.$refs.gForm.validate((valid) => {
        if (valid) {
          this.dialogFormVisible2 = false;
          let loading = this.$loading();
          let temp_json = {};
          this.template_params.forEach((item) => {
            temp_json[item] = this.gForm[item];
          });
          this.gForm.parameter_map = JSON.stringify(temp_json);
          console.log(this.gForm);
          this.postGenerateContract(this.gForm)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
              this.loadData();
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .el-date-editor {
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }
  }

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
  }
  .custom_button {
    background-color: #4db9d5;
  }
  .filter_btns {
    display: flex;
    align-items: center;
    .plain {
      color: #4db9d5;
      border-color: #4db9d5;
      background-color: #fff;
    }
    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.operations {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    font-size: 14px;
    color: #406bff;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 13px;
    }
  }
}
</style>
